import React from "react";
import {graphql, useStaticQuery} from 'gatsby';
import Blog from '../../layouts/Blog/';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import HomeContentSection from '../../layouts/Home/HomeContentSection';

const BlogPageEn = (props) => {
  const data = useStaticQuery(graphql`
    query BlogPostEn {
      allContentfulArticleDeBlogue(filter: {node_locale: {eq: "en-CA"}}) {
        edges {
          node {
            title
            createdAt(locale: "en", formatString: "MMMM Do YYYY")
            excerpt
            slug
            postTypes {
              name
            }
            headerImg {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      allContentfulTypeDarticle(sort: {fields: name, order: ASC}, filter: {node_locale: {eq: "en-CA"}}) {
        edges {
          node {
            name
          }
        }
      }
    }
  `)
  return (
    <Layout 
    lang='en-CA' 
    switchLink='/blogue'
    slider={false} 
    title='Blog'>
      <Seo 
        title='Master Your Digital Signage'
        description={'News and tips to maximize your digital signage tools with Attractif experts, from screen to content creation.'}
       />
       <Blog lang="en-CA" posts={data.allContentfulArticleDeBlogue.edges} postTypes={data.allContentfulTypeDarticle.edges}/>
       <HomeContentSection lang="en-CA"/>
    </Layout>
  )
}

export default BlogPageEn